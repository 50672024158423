import React from 'react';
// eslint-disable-next-line no-unused-vars
import { BrowserRouter as Route, Link } from "react-router-dom";
import { Button, Card, Col } from 'react-bootstrap';

export default function EquipmentCard(props) {
    const MAX_LENGTH = 247;
    if (!props.imageSrc) {
        return null;
    } else {
        return (
            <Col sm={12} md={6} lg={4}>
            <Card className='border-0 mb-4'>
                    <Link to={`equip/${props.id}`}>
                    <Card.Img
                        variant='top'
                        src={props.imageSrc}
                        alt={props.imageTitle}
                        />
                    </Link>
                    <Card.Title className='text-center'>{props.title}</Card.Title>
                    <Card.Text>
                        {props.description.length > MAX_LENGTH ?
                            (
                                <>{`${props.description.substring(0, 247)}...`}</>
                            ) : (
                                <>{props.description}</>
                        )
                        }
                        <br /><strong>Full Day Rental:</strong> ${props.fullDayPrice}<br /><strong>Half Day Rental:</strong> ${props.halfDayPrice}</Card.Text>
                    <Link className="text-center" to={`equip/${props.id}`}>
                        <Button variant='dark'>Learn More</Button>
                    </Link>
                </Card>
             </Col>
        )
    }
}