import React from 'react';
// eslint-disable-next-line no-unused-vars
import { BrowserRouter as Route, Redirect, useParams } from "react-router-dom";
import { Container, Row, Col, Button } from 'react-bootstrap';
import equipment from '../equipment.json';

export default function Equipment() {
    let { equipmentId } = useParams();
    const displayEquipment = equipment.filter(equip => equip.id === parseInt(equipmentId));

    let equip = displayEquipment[0];
    if (!displayEquipment) {
        return (
            <Redirect to='/boats' />
        )
    } else {
        return (
            <Container fluid>
                <Row className='text-center'>
                    <Col>
                        <img className='rounded img-fluid' src={equip.imageSrc} alt={equip.imageTitle} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h1 className='text-center'>{equip.title}</h1>
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col>
                        <p>{equip.description}</p>
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col>
                        <strong>Full Day Rental:</strong> ${equip.fullDayPrice}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <strong>Half Day Rental:</strong> ${equip.halfDayPrice}
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col>
                        <Button variant='dark' href='tel:(555) 555-5555'>Call to Reserve!</Button>
                    </Col>
                </Row>
            </Container>
        )
    }



}