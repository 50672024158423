import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import EquipmentCard from '../components/EquipmentCard';
import equipment from '../equipment.json';

export default function Boats() {
    const firstFilter = equipment.filter(equip => equip.show);
    const displayEquipment = firstFilter.filter(equip => equip.equipmentType === 'boats');
    return (
        <Container fluid className='page'>
            <Col>
                <h1 className='header'>Boats</h1>
                <Container fluid>
                    <Row>
                        {displayEquipment.reverse().map(equip => {
                            return (
                                <EquipmentCard
                                    key={equip.id}
                                    id={equip.id}
                                    title={equip.title}
                                    link={equip.link}
                                    imageSrc={equip.imageSrc}
                                    imageTitle={equip.imageTitle}
                                    cardType={equip.equipmentType}
                                    description={equip.description}
                                    halfDayPrice={equip.halfDayPrice}
                                    fullDayPrice={equip.fullDayPrice}
                                />
                            )
                        })}
                    </Row>
                </Container>
            </Col>
        </Container>
    )
};
