import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';

export default function NavigationBar() {
    return (
        <Navbar bg='light' expand='md'>
            <Navbar.Brand href='/'>AllFun Rentals</Navbar.Brand>
            <Navbar.Toggle aria-controls='basic-navbar-nav' />
            <Navbar.Collapse className='justify-content-end' id='basic-navbar-nav'>
                <Nav>
                    <Nav.Link href='/'>Home</Nav.Link>
                    {/* <NavDropdown title='Surf' id='surf-dropdown'>
                        <NavDropdown.Item href='/boats'>Boats</NavDropdown.Item>
                        <NavDropdown.Item href='/jetskis'>Jet Skis</NavDropdown.Item>
                        <NavDropdown.Item href='/towables'>Towables</NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown title='Turf' id='turf-dropdown'>
                        <NavDropdown.Item href='/sidebysides'>Side by Sides</NavDropdown.Item>
                        <NavDropdown.Item href='/4Wheelers'>4 Wheelers</NavDropdown.Item>
                        <NavDropdown.Item href='/dirtbikes'>Dirt Bikes</NavDropdown.Item>
                    </NavDropdown> */}
                    <Nav.Link href='/boats'>Boats</Nav.Link>
                    <Nav.Link href='/towables'>Towables</Nav.Link>
                    <Nav.Link href='/about'>About Us</Nav.Link>
                    <Nav.Link href='/contact'>Contact Us</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}