import React from 'react'

export default function Footer() {
    return (
        <footer className='footer'>
            <div className='container-fluid'>
                <span> &copy; 2021 AllFun Rentals, LLC. All rights reserved </span>
            </div>
        </footer>
    )
}